import React, { useState } from "react";
import { useFetchData } from "../../helper/useFetchData";

interface ClicktoRevealItem {
  id: number;
  heading: string;
  clicktorevealitems: {
    id: number;
    Description: string | null;
    ListItems: string | null; // ListItems might be null
    Cta: string | null;
    ButtonLink: {
        LinkText:  string | null;
        link:  string | null;
        ButtonClass:  string | null;
      }
  };
  image: {
    url: string;
  };
  
}

const ClicktoReveal: React.FC = () => {
  const { data, loading, error } = useFetchData(
    `page?populate=ClicktoReveal&populate=ClicktoReveal.image&populate=ClicktoReveal.clicktorevealitems&populate=ClicktoReveal.clicktorevealitems.ButtonLink`
  );

//   console.log(data);
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const handleToggle = (index: number) => {
    setActiveIndex(index); 
  };

  if (loading) return <div>Loading ClicktoReveal...</div>;
  if (error) return <div>Error loading ClicktoReveal: {error.message}</div>;

  const clickToRevealItems: ClicktoRevealItem[] = data?.data?.ClicktoReveal || [];
  if (!Array.isArray(clickToRevealItems) || clickToRevealItems.length === 0) {
    return <div>No ClicktoReveal items available.</div>;
  }

  return (
    <section className="accordion-hori flex 991-custom:block  md:gap-3 justify-content-between">
      {clickToRevealItems.map((item, index) => {
        const { clicktorevealitems, image, heading } = item;
        const description = clicktorevealitems?.Description || "No description available";
        const listItems = clicktorevealitems?.ListItems
          ? clicktorevealitems.ListItems.split("\n").filter(Boolean) // Split and remove empty lines
          : [];
          const buttonLink = clicktorevealitems?.ButtonLink;

        return (
          <div
            key={item.id}
            className={`item-main-w 991-custom::not(:last-child):mb-[15px] 1025-min-custom:w-1/3 bg-gray-200 1025-min-custom:min-h-[380px] cursor-pointer  1025-min-custom:justify-center  ${activeIndex === index ? "expanded !w-full p-0 block" : "collapsed p-[18px] flex"}`}
            onClick={() => handleToggle(index)}
          >
            <div className={`item-wrap flex 1025-min-custom:flex-col 1025-min-custom:justify-center 1025-max-custom:items-center 1025-max-custom:gap-[20px]  ${
      activeIndex === index ? "!hidden" : ""}`}>
              <div className="title-icon 1025-min-custom:mb-[20px] md:w-[75px] md:h-[75px] md:min-w-[75px] md:min-h-[75px]  w-[55px] h-[55px] min-w-[55px] min-h-[55px] p-[15px] bg-[#001acb] md:p-[18px] rounded-full">
                <img
                  className="filter brightness-0 invert"
                  src={image.url}
                  alt={`icon for ${heading}`}
                  width="88"
                  height="88"
                />
              </div>
             
              <h3 className="text-[22px] leading-[33px] 1025-min-custom:min-h-[66px]">{heading}</h3>
            </div>
        
            <div className={`item-info text-white h-100 min-h-[380px] bg-brand-2 relative  ${
      activeIndex === index ? "p-[40px] pt-[40px] px-[50px] pb-[50px] 1248-custom:p-[20px] block" : "hidden"}`}>
              <div className="flex gap-[30px] 575-custom:flex-col">
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2">
                  <div className="item-info-con">
                    <h3 className="text-[20px] leading-[25px] md:text-[26px] md:leading-[1.2]">{heading}</h3>
                    <h4 className="text-[20px] leading-[25px] md:text-[22px] lg:leading-[1.2] pb-[15px]">{description}</h4>
                  
                    <img
                    alt={`icon for ${item.heading}`}
                    width="88"
                    height="88"
                    src={item.image.url}
                  />
                  </div>
                </div>
                <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/2">
                  <div className="item-info-con">
                    <ul>
                      {listItems.length > 0 ? (
                        listItems.map((listItem, idx) => (
                          <li key={idx} className="flex align-items-center">
                            <div className="list-arrow">
                              <img
                                className="w-[21px]"
                                alt="list-arrow"
                                width="21"
                                height="22"
                                src="https://www.cloud4c.com/sites/default/files/inline-images/list-arrow.png"
                              />
                            </div>
                            <div className="list-text ml-[10px] w-[calc(100%-31px)] max-w-[80%]">
                              <p className="font-normal text-[16px] leading-[28px]">{listItem}</p>
                            </div>
                          </li>
                        ))
                      ) : (
                        <li>No list items available</li>
                      )}
                    </ul>
                    <div className="absolute right-0 bottom-0 ">
                {buttonLink ? (
                  <a
                    className={`${buttonLink.ButtonClass || ""} bg-contain flex items-center gap-[10px] text-base bg-brand-1 hover:text-brand-1 hover:bg-white border  border-[#0025c9]  px-[30px] pt-[13px] pb-[10px]  after:content-[''] after:w-[25px] after:h-[27px] after:bg-[url('https://www.cloud4c.com/sites/default/files/inline-images/arrow-link.png')] after:bg-no-repeat after:bg-center   after:bg-[length:18px_13px] hover:after:bg-[url('https://www.cloud4c.com/themes/custom/cloud4c/images/revamp/home/arrow-link-blue.png')]` }
                    href={buttonLink.link || "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {buttonLink.LinkText || "Explore more"}
                  </a>
                ) : (
                  <span className="text-gray-400">No CTA available</span>
                )}
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default ClicktoReveal;
