import React from 'react';
import logo from './logo.svg';
import './App.css';
import TitleInlinePara from './components/TitleInlinePara';
import BannerSlider from './components/BannerSlider';
import ClickReveal from  './components/ClicktoReveal'
import ImgcardWithIcon from './components/ImgCardWithIcon';
import ImgTxtSlider from './components/ImgTxtSlider';
import Tabaccordian from './components/tabaccordian';

function App() {
  return (
    <>
    <BannerSlider/>
      <TitleInlinePara/>
       <ClickReveal/>
      <ImgcardWithIcon/>
      {/* <ImgTxtSlider/> */}
      <Tabaccordian/> 
        </>
 
  );
}

export default App;
