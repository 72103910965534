import React, { useState, useEffect, useMemo } from "react";
import { useFetchData } from "../../helper/useFetchData";

interface TabContent {
  type: string;
  children?: { text: string; type?: string }[];
  language?: string;
}

interface TabsChild {
  id: number;
  IdContent: string;
  tabtitle: string;
  content: TabContent[];
  isActiveTab?: boolean;
}

interface Tab {
  id: number;
  ContainerClass?: string;
  TabTitleClass?: string;
  tabschild: TabsChild[];
}

const TabsComponent: React.FC = () => {
  const { data, loading, error } = useFetchData("page?populate=Tab.tabschild");

  const tabs: Tab[] = useMemo(() => data?.data?.Tab || [], [data]);

  const [activeTabs, setActiveTabs] = useState<{ [key: number]: number }>({});
  const [isAccordionView, setIsAccordionView] = useState(false);

  // Handle screen resize to determine accordion view
  useEffect(() => {
    const handleResize = () => {
      setIsAccordionView(window.innerWidth < 991);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initialize on mount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Initialize active tabs for all containers
  useEffect(() => {
    if (tabs.length > 0) {
      const initialActiveTabs = isAccordionView
        ? {} // If in accordion view, no tabs are active by default
        : tabs.reduce((acc, tab) => {
            const activeChild = tab.tabschild.find((child) => child.isActiveTab) || tab.tabschild[0];
            return { ...acc, [tab.id]: activeChild?.id || 0 };
          }, {});
      setActiveTabs(initialActiveTabs);
    }
  }, [tabs, isAccordionView]);

  const handleTabClick = (tabId: number, childId: number) => {
    setActiveTabs((prev) => ({ ...prev, [tabId]: childId }));
  };

  const handleAccordionToggle = (tabId: number, childId: number) => {
    setActiveTabs((prev) => {
      // If the clicked tab is already active, close it
      if (prev[tabId] === childId) {
        return {}; // Close all tabs
      }
      // Otherwise, activate the clicked tab
      return { [tabId]: childId };
    });
  };

  if (loading) return <div>Loading tabs...</div>;
  if (error) return <div>Error loading tabs: {error.message}</div>;
  if (!tabs.length) return <div>No tabs available.</div>;

  return (
    <div className="max-w-1170px-container mx-auto px-[30px]">
      {tabs.map(({ id, ContainerClass, TabTitleClass, tabschild }) => (
        <div key={id} className={`custom-tabs ${ContainerClass || ""} 991-custom:pb-0`}>
          {!isAccordionView ? (
            // Tab Navigation for Desktop View
            <>
              <ul className={`nav nav-tabs ${TabTitleClass || ""}`}>
                {tabschild.map(({ id: childId, tabtitle }) => (
                  <li key={childId} className="nav-item">
                    <button
                      className={`nav-link ${activeTabs[id] === childId ? "active" : ""}`}
                      onClick={() => handleTabClick(id, childId)}
                      dangerouslySetInnerHTML={{ __html: tabtitle }}
                    />
                  </li>
                ))}
              </ul>

              <div className="tab-content">
                {tabschild
                  .filter(({ id: childId }) => childId === activeTabs[id])
                  .map(({ id: childId, content }) => (
                    <div key={childId} className="tab-pane active">
                      {content.map(({ type, children, language }, index) => {
                        if (type === "paragraph") {
                          return (
                            <p key={index}>
                              {children?.map(({ text }, i) => (
                                <span key={i}>{text}</span>
                              ))}
                            </p>
                          );
                        }

                        if (type === "code" && language === "html") {
                          const htmlContent = children?.map(({ text }) => text).join("") || "";
                          return (
                            <div
                              key={index}
                              dangerouslySetInnerHTML={{ __html: htmlContent }}
                            />
                          );
                        }

                        return (
                          <div key={index}>
                            {children?.map(({ text }, i) => (
                              <span key={i}>{text}</span>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  ))}
              </div>
            </>
          ) : (
            // Accordion View for Mobile
            <div className="accordion">
              {tabschild.map(({ id: childId, tabtitle, content }) => (
                <div key={childId} className="accordion-item">
                  <button
                    className={`accordion-header ${
                      activeTabs[id] === childId ? "active" : ""
                    }`}
                    onClick={() => handleAccordionToggle(id, childId)}
                    dangerouslySetInnerHTML={{ __html: tabtitle }}
                  />
                  {activeTabs[id] === childId && (
                    <div className="accordion-content">
                      {content.map(({ type, children, language }, index) => {
                        if (type === "paragraph") {
                          return (
                            <p key={index}>
                              {children?.map(({ text }, i) => (
                                <span key={i}>{text}</span>
                              ))}
                            </p>
                          );
                        }

                        if (type === "code" && language === "html") {
                          const htmlContent = children?.map(({ text }) => text).join("") || "";
                          return (
                            <div
                              key={index}
                              dangerouslySetInnerHTML={{ __html: htmlContent }}
                            />
                          );
                        }

                        return (
                          <div key={index}>
                            {children?.map(({ text }, i) => (
                              <span key={i}>{text}</span>
                            ))}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TabsComponent;
