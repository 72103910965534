import { useState, useEffect } from "react";
import axios from "axios";

const BASE_URL = "https://cloud4c-poc-strapi.focal-learning.com/";

export const useFetchData = (endpoint: string) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log(`${BASE_URL}api/${endpoint}`);
        const response = await axios.get(`${BASE_URL}api/${endpoint}`);
        // console.log("111",response.data.data);
        // Preprocess the data to include full image URLs
        const processedData = preprocessData(response.data);
        setData(processedData);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [endpoint]);

  return { data, loading, error };
};

// Helper function to process and add full URLs to image fields
const preprocessData = (data: any) => {
  if (!data || typeof data !== "object") return data;

  // Recursively traverse and process image URLs in the data
  const traverse = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === "object" && obj[key] !== null) {
        traverse(obj[key]);
      } else if (key === "url" && typeof obj[key] === "string") {
        obj[key] = `${BASE_URL}${obj[key]}`;
      }
    }
  };

  traverse(data);
  return data;
};
