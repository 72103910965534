import React from "react";
import { useFetchData } from "../../helper/useFetchData";
import "./style.css";

interface IconCard {
  id: number;
  icon: { url: string; alt: string };
  title: string;
  description: string;
}

interface ImgCardIcon {
  sectionclass: string;
  bgimg: { url: string; alt: string };
  Imgleft: {
    formats: { small: { url: string }; [key: string]: { url: string } };
    alt: string;
  }[];
  button: { 
    url: string; 
    text: string ;
    LinkText: string;
  link: string;
  ButtonClass: string;
  };
  cardlayout: IconCard[];
  
}

const ImgCardWithIcon: React.FC = () => {
  const { data, loading, error } = useFetchData(
    "page?populate=Imgcardicon.cardlayout&populate=Imgcardicon.bgimg&populate=Imgcardicon.Imgleft&populate=Imgcardicon.button&populate=Imgcardicon.cardlayout.icon"
  );
console.log(data);
  if (loading) return <div>Loading content...</div>;
  if (error) return <div>Error loading content: {error.message}</div>;

  const sectionData: ImgCardIcon = data?.data?.Imgcardicon;

  if (!sectionData) return <div>No data available.</div>;

  return (
    <section className={`most-advance-wrap relative ${sectionData.sectionclass}`}>
      {/* Background Image */}
      <img
        className="block w-full h-auto"
        src={sectionData.bgimg.url}
        alt={sectionData.bgimg.alt}
        width="1920"
        height="726"
        loading="lazy"
      />
      <div className="max-w-1170px-container mx-auto text-left lg:px-[15px] px-[30px]">
        <div className="most-adv-con flex flex-wrap items-end">
          {/* Left Image Section */}
          <div className="lg:w-5/12 pr-0 pb-0">
            <div className="media-left">
            {sectionData.Imgleft && sectionData.Imgleft.length > 0 && (
                <img
                  className="lazy w-full"
                  src={sectionData.Imgleft[0].formats.small.url}
                  alt={sectionData.Imgleft[0].alt}
                  loading="lazy"
                />
              )}
              
                <a
                  className={`${sectionData.button.ButtonClass} bg-contain flex items-center gap-[10px] text-base bg-brand-1 hover:text-brand-1 hover:bg-white border  border-[#0025c9]  px-[30px] pt-[13px] pb-[10px]  after:content-[''] after:w-[25px] after:h-[27px] after:bg-[url('https://www.cloud4c.com/sites/default/files/inline-images/arrow-link.png')] after:bg-no-repeat after:bg-center   after:bg-[length:18px_13px] hover:after:bg-[url('https://www.cloud4c.com/themes/custom/cloud4c/images/revamp/home/arrow-link-blue.png')] `}
                  href={sectionData.button.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {sectionData.button.LinkText}
                </a>
            </div>
          </div>
          {/* Right Content Section */}
          <div className="lg:w-7/12 pl-0">
            <div className="box-right">
              <h2 className="text-white md:text-black pl-2 text-3xl font-bold">
                {/* {sectionData.title} */}
              </h2>
              {/* Cards Section */}
              <div className="adv-box-wrap grid grid-cols-2">
                {sectionData.cardlayout.map((card, index) => (
                  <div
                    key={card.id}
                    className={`box-m ${
                      index % 3 === 0 ? "bg-gray-200" : "bg-white"
                    } p-4 flex-1  shadow-md`}
                  >
                    <div className="top-icon flex justify-between items-center">
                      <img
                        className="lazy"
                        src={card.icon.url}
                        alt={card.icon.alt}
                        width="75"
                        height="75"
                        loading="lazy"
                      />
                      <div
                        className={`num-right text-brand-3`}
                      >
                        <h2 className="text-lg font-bold">{card.title}</h2>
                      </div>
                    </div>
                    <div className="bottom-text mt-4">
                      <p className="text-sm text-gray-700">{card.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImgCardWithIcon;
